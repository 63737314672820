<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ $t('EMPLOYEES.TITLE_EDIT') }}</h5>
    </div>
  </template>
  <template #toolbar>
    <div class="example-tools justify-content-center">
      <router-link :to="{ name: 'businessUser' }" class="btn btn-secondary font-weight-bold btn-sm">
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Navigation/Angle-double-left.svg" />
        </span>
        {{ $t('BASE.BACK') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <b-form class="form" @submit.stop.prevent="onSubmit">
      <b-container fluid class="p-0">
        <b-row>
          <b-col cols="12" md="8" offset-md="2">
            <b-form-group id="input-group-3" :label="`${$t('FORM.AVATAR')}`" label-for="input-3" label-cols-md="2" label-align-md="right">
              <FileImage
                id="input-3"
                v-model="$v.form.avatar.$model"
                accept="image/*"
                aria-describedby="input-3-feedback"
                @input="clearServerError('avatar')"
              />
              <b-form-invalid-feedback id="input-3-feedback" :class="{'d-block': validateState('avatar') === false}">
                <template v-if="serverErrors.avatar">{{ serverErrors.avatar[0] }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-1" :label="`${$t('FORM.FIRST_NAME')}`" label-for="input-1" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-1"
                v-model="$v.form.firstName.$model"
                :state="validateState('firstName')"
                :placeholder="$t('PLACEHOLDER.FIRST_NAME')"
                trim
                @input="clearServerError('firstName')"
                aria-describedby="input-1-feedback"
              />
              <b-form-invalid-feedback id="input-1-feedback">
                <template v-if="serverErrors.firstName">{{ serverErrors.firstName[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.FIRST_NAME') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-6" :label="`${$t('FORM.LAST_NAME')}`" label-for="input-6" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-6"
                v-model="$v.form.lastName.$model"
                :state="validateState('lastName')"
                :placeholder="$t('PLACEHOLDER.LAST_NAME')"
                trim
                @input="clearServerError('lastName')"
                aria-describedby="input-6-feedback"
              />
              <b-form-invalid-feedback id="input-6-feedback">
                <template v-if="serverErrors.lastName">{{ serverErrors.lastName[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.LAST_NAME') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-4" :label="`${$t('FORM.PHONE')}`" label-for="input-4" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-4"
                v-model="$v.form.phone.$model"
                :state="validateState('phone')"
                :placeholder="$t('PLACEHOLDER.PHONE')"
                @input="clearServerError('phone')"
                aria-describedby="input-4-feedback"
              />
              <b-form-invalid-feedback id="input-4-feedback">
                <template v-if="serverErrors.phone">{{ serverErrors.phone[0] }}</template>
                <template v-else-if="!$v.form.phone.alphaPhone">{{ $t('VALIDATION.INVALID', { name: $t('FORM.PHONE') }) }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.PHONE') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-5" :label="`${$t('FORM.EMAIL')}`" label-for="input-5" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-5"
                v-model="$v.form.email.$model"
                :state="validateState('email')"
                :placeholder="$t('PLACEHOLDER.EMAIL')"
                @input="clearServerError('email')"
                aria-describedby="input-5-feedback"
              />
              <b-form-invalid-feedback id="input-5-feedback">
                <template v-if="serverErrors.email">{{ serverErrors.email[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.EMAIL') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-2" :label="`${$t('FORM.ROLE')}`" label-for="select-1" label-cols-md="2" label-align-md="right">
              <SelectInfinityScroll
                id="select-1"
                v-model="$v.form.role.$model"
                :search.sync="searchRole"
                :state="validateState('role') === false"
                :items="optionsRole"
                clearable
                @change="$v.form.groups.$reset()"
                @input="clearServerError('role')"
                aria-describedby="select-1-feedback"
              />
              <b-form-invalid-feedback id="select-1-feedback" :class="{'d-block': validateState('role') === false}">
                <template v-if="serverErrors.role">{{ serverErrors.role[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.ROLE') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              v-if="form.role && form.role.uuid === 'ROLE_BUSINESS_ADMIN'"
              :label="`${$t('FORM.GROUPS')}`"
              label-for="select-2"
              label-cols-md="2"
              label-align-md="right"
            >
              <SelectInfinityScroll
                id="select-2"
                v-model="$v.form.groups.$model"
                :search.sync="searchGroups"
                :state="validateState('groups') === false"
                clearable
                multiple
                api="company/user-groups"
                @input="clearServerError('groups')"
                aria-describedby="select-1-feedback"
              />
              <b-form-invalid-feedback id="select-1-feedback" :class="{'d-block': validateState('groups') === false}">
                <template v-if="serverErrors.groups">{{ serverErrors.groups[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.GROUPS') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-7" :label="`${$t('FORM.ENABLED')}`" label-for="check-1" label-cols-md="2" label-align-md="right">
              <span class="switch switch-icon">
                <label>
                  <input
                    v-model="form.enabled"
                    id="check-1"
                    type="checkbox"
                  >
                  <span />
                </label>
              </span>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="text-right">
              <b-button :disabled="loading || $v.form.$anyError" type="submit" variant="primary" size="sm">
                <b-spinner v-if="loading" small variant="light" />
                {{ $t('FORM.SUBMIT') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </template>
</KTCard>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, helpers, requiredIf } from 'vuelidate/lib/validators'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Repo from '@/core/repository/company/businessUserRepository'
import RepoFile from '@/core/repository/company/fileRepository'
import { TIMEOUT_REDIRECT } from '@/constants/base'
import KTCard from '../../../../content/Card'
import InputForm from '../../../../../components/forms-items/input'
import serverVuelidate from '../../../../../mixins/serverVuelidate'
import { TEL } from '../../../../../helpers/validation'
import FileImage from '../../../../../components/forms-items/FileImage'
import SelectInfinityScroll from '../../../../../components/forms-items/SelectInfinityScroll'

export default {
  name: 'EditBusinessUser',
  components: {
    SelectInfinityScroll,
    FileImage,
    InputForm,
    KTCard,
  },
  mixins: [validationMixin, serverVuelidate],
  data() {
    return {
      loading: false,
      file: null,
      optionsRole: [
        {
          uuid: 'ROLE_BUSINESS_OWNER',
          name: this.$t('EMPLOYEES.ROLES.ROLE_BUSINESS_OWNER'),
        },
        {
          uuid: 'ROLE_BUSINESS_ADMIN',
          name: this.$t('EMPLOYEES.ROLES.ROLE_BUSINESS_ADMIN'),
        },
      ],
      searchRole: '',
      searchGroups: '',
      form: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        plainPassword: '',
        groups: null,
        role: null,
        avatar: null,
        enabled: true,
      },
      validations: {
        form: {
          firstName: { required },
          lastName: { required },
          email: { required },
          avatar: {},
          role: { required },
          groups: {
            required: requiredIf(() => this.form?.role?.uuid === 'ROLE_BUSINESS_ADMIN'),
          },
          phone: {
            required,
            alphaPhone: helpers.regex('alpha', TEL),
          },
        },
      },
    }
  },
  mounted() {
    this.fetchData()
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('EMPLOYEES.TITLE_EDIT') }])
  },
  methods: {
    fetchData() {
      Repo.get(this.$route.params.id).then(({ data }) => {
        this.setItem(data.payload)
        // this.form.groups = this.form.groups ? this.form.groups[0] : this.form.groups
        this.form.role = data.payload.role ? this.optionsRole.find((f) => f.uuid === data.payload.role) : null
      })
    },
    setItem(item) {
      Object.keys(this.form).forEach((propName) => {
        if (!this.form.hasOwnProperty(propName) && !item.hasOwnProperty(propName)) return

        if (item[propName] !== null && item[propName] !== undefined) {
          this.form[propName] = item[propName]
        }
      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    addFile(file) {
      if (file?.uuid) return file
      const formData = new FormData()
      formData.append('file', file)
      return RepoFile.post(formData)
    },
    async onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.clearServerErrors()
      this.loading = true

      const obj = { ...this.form }
      if (this.form.avatar) {
        const res = await this.addFile(this.form.avatar)
        obj.avatar = res.uuid || res.data.payload.uuid
      }
      obj.role = obj.role.uuid
      obj.groups = obj.groups ? obj.groups.map((m) => m.uuid) : obj.groups
      Repo.patch(this.$route.params.id, obj).then(() => {
        this.$bvToast.toast(this.$t('TOAST.EDITED'), {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 2000,
          solid: true,
        })
        setTimeout(() => this.$router.push({ name: 'businessUser' }), TIMEOUT_REDIRECT)
      }).catch((err) => {
        this.seServerErrors(err.response.data)
      })
        .finally(() => this.loading = false)
    },
  },
}
</script>
